@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

.App {
  background-color: #ffffff;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-family: 'Alike Angular', serif;
}

a {
  color: inherit;
  text-decoration: none;
  display: inherit;
}
.Header {
  height: 26.5vh;
 
background-color:  white;
  width: 100vw;
  z-index: 2;
  display: flex;
  position: fixed;
  flex-direction: column;
 
  justify-content: space-between;
}
.Announcmentpage {
  margin-top: 16vh;
  display: flex;
  height: 85vh;
  flex-direction: column;
}
.Adminstrationpage {
  margin-top: 16vh;

  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Adminstrationpage p {
  color: rgba(41, 36, 41, 0.8);
  font-size: 18px;
}
.Adminstrationpage h1 {
  color: rgba(41, 36, 41, 0.8);
  font-size: 32px;
  text-align: center;
}
.Adminstrationpage ul {
  list-style-type: lower-roman;
  margin-left: 0;
  padding-left: 30px;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.column-container h3 {
  color: rgba(41, 36, 41, 0.8);
  height: 40px;
}
.column-container {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  border-radius: 5%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.column-container:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.Erorrpage {
  margin-top: 16vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(207, 19, 5);
  font-weight: bold;

  background-color: rgb(43, 42, 42);
}
#types {
  height: 10px;
  font-family: 'Alike Angular', serif;
  margin: 10px;
}
.Erorrpageimage img {
  height: 200px;
  width: 200px;
}
.NoFoundPage {
  margin-top: 16vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
  background-color: rgb(43, 42, 42);
  color: white;
}
.NoFoundPageimage img {
  height: 200px;
  width: 200px;
}
.datelist {
  display: flex;
  flex-direction: row;

  justify-content: center;

  flex-wrap: wrap;
}
.Newspage {
  margin-top: 16vh;
  display: flex;
  flex-direction: column;
}
.Newslist {
  display: flex;
  flex-direction: column;
  width: 900px;
}
.Anouncmentlist {
  width: 400px;
}
.EachHeaderbutton {
  display: flex;
  flex-direction: column-reverse;
  font-size: 15px;
  position: relative;

  align-items: center;
  justify-content: center;
}
#PAgesh1 {
  margin-top: 100px;
}
.headermobile {
 
  width: 30px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
 

}
.Logo-container {
  height: 150px;
 

 
  display: flex;
  flex-direction: row;
 
}
.Logo-container img {
  height: 120px;
  width: 150px;
}

.headerText{
  width: 500px;
  font-size: 24px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  margin-right: 20px;
}

.headerTexteach{
  margin: 4px;
  font-family: 'Alike Angular', serif;
}
.Header-buttons {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'Alike Angular', serif;
  justify-content: center;
  background: rgb(0,103,184);
  background: linear-gradient(173deg, rgba(0,103,184,1) 0%, rgba(0,103,184,1) 80%, rgba(255,255,255,1) 100%);

  margin-right: 100px;
}
.feature_Vidos {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: max-content;
}
.feature_Vidocontent {
  height: 150px;
  width: 90%;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;

}
.feature_Vidocontent iframe {
  top: 0;
  z-index: 4;
  left: 0;
  width: 100%;
  height: 100%;
}
#title {
  font-weight: bold;

  font-size: 24px;
}
.feature_Vidos_header {
  height: 60px;
  width: 100%;

  display: flex;
  flex-direction: row;
justify-content: center;
  align-items: center;
  
}
.video_list{
  width: 100%;
  padding: 2px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

}
.Footer {
  background: rgb(0,103,184);
  background: linear-gradient(173deg, rgba(0,103,184,1) 0%, rgba(0,103,184,1) 80%, rgba(255,255,255,1) 100%);height: 68vh;
  padding: 10px;
}
.Socialcard {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #252525;
  margin: 10px;
  border-radius: 5px;
}
.inline-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Header-buttons ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Header-buttons #selected {
  background-color: #fdfdfd;
  color: black;
}

.Header-buttons li:hover #icon {
  color: orange !important;

  cursor: pointer;
}
.Header-buttons li:hover {
  cursor: pointer;
}
.Header-buttons li {
  list-style: none;
  color: rgb(255, 255, 255) ;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 3px;
  margin-inline: 10px;
  height: 40px;
  min-width: 70px;
}
.HomeScreen {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 16.5vh;
  background-color: rgba(243, 241, 241, 0.651);
}
.Subheadercomponent {
  background-color: #0067b8;;
  height: 50px;
  width: 100vw;

  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
.Subheadercomponent ul {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Collegescreen {
  margin-top: 16vh;
  display: flex;
  flex-direction: column;
}

.ECpageBody {
  flex: 1;
  width: 100vw;
  flex-wrap: nowrap;

  display: flex;
  flex-direction: row;
}
.DepartmentCardList {
  flex: 1;
  display: flex;
  flex-direction: row;

  margin: 10px;

  align-items: center;
}
.depCardListslideshow {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  max-width: 890px;
}
.DepartmentCards {
  background-color: rgb(250, 250, 250);
  border-radius: 2px;
  box-shadow: rgba(12, 11, 11, 0.068) 0.5px 0.5px 0.5px 2.5px;
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  margin-left: 20px;
  margin-top: 10px;

  width: 200px;
}
.DepartmentCards img {
  height: 100%;
  width: 100%;
}
.DepcardText {
  height: 74px;
  width: 150px;

  display: flex;
  text-align: center;
}
.slide {
  display: inline-flex;
  flex-direction: row;

  height: 400px;

  border-radius: 40px;
}
.DepcardText p {
  font-weight: bold;
  width: 150px;
  white-space: normal;

  size: 38px;
}
.DepartmentCards:hover {
  background-color: rgb(245, 243, 243);
  cursor: pointer;
}
.ECLeftBody {
  flex: 2;

  display: flex;
  margin: 10px;

  flex-direction: column;
  background-color: rgb(255, 255, 255);
}
.titles {
  display: flex;

  align-items: center;
  justify-content: center;
}
.inline-smallcard {
  display: flex;
  height: 100px;
  width: 100%;
}
.titles p {
  font-weight: bold;
  font-size: 22px;
}
.AnnouncmentCardlsts {
  display: flex;
  flex-direction: column;
}

.PartnerComponent {
  width: 100px;
  height: 100px;
  margin: 10px;
}
.PartnersCards {
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
}
.CollageFacility {
  margin-top: 16vh;
  display: flex;
  flex: 1;
  min-height: 85vh;

  flex-direction: column;
}
.FacilityComponent .titles {
  align-self: flex-start;
  margin-left: 60px;
}
.FacilityComponent {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1000px;

  justify-content: center;
}
.facilitybody {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.Facilitycardlist {
  display: flex;
  flex-direction: column;

  justify-content: center;
}
.CollageAdminstartion {
  margin-top: 16vh;
  display: flex;
  flex-direction: column;
  min-height: 900px;
}
.CollageAdminstartionbody {
  display: flex;
  flex: 1;

  flex-direction: column;
}
.adminofficetitles {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
.admin-message {
  display: flex;
  flex-direction: row;
  box-shadow: rgba(12, 11, 11, 0.061) 1.2px 1.95px 1.95px 2.6px;
  background-color: rgb(255, 255, 255);

  margin-inline: 10px;
}
.Contact-links {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-inline: 10px;
  background-color: #312e3118;
}
.Contact-links .links {
  display: flex;
  margin-inline: 30px;
}
.admin-pic {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-pic p,
h3 {
  margin: 0px;
}
.messagetext {
  flex: 1;

  width: 200px;
  padding: 10px;
  height: 300px;
  overflow: hidden;
}
.admin-message img {
  margin-top: 10px;
  height: 300px;
  width: 300px;
}
.messagehead {
  flex: 3;
  display: flex;
  flex-direction: column;
}
.sideoffices-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AdminSides {
  flex: 1;

  display: flex;
}
.Officebutton {
  height: 50px;
  background-color: rgba(53, 56, 56, 0.142);
  color: rgb(13, 13, 13);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.background {
  flex: 1;
  padding-inline: 100px;
}

.Detailprofile table {
  background-color: #252525;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
table tr {
  display: flex;
  border: 1px solid black;
  flex: 1;
  background-color: white;
}
table th {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-list p {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.ImageCircle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ImageCircle img {
  height: 100px;
  margin-inline: 10px;
  width: 100px;
  border-radius: 50px;
}
.loadpages {
  width: 100%;
  height: 300px;

  display: flex;
  align-items: center;
  justify-content: center;
}
table td {
  flex: 1;
  display: flex;
  align-items: center;

  border-left: 1px solid black;
}
.smallcard {
  height: 50%;
  width: 50px;
  border-radius: 10px;
  margin: 10px;
}
.Facilitycard {
  width: 400px;
  height: 300px;

  border-radius: 20px;
}
.FacilityComponent P {
  flex: 1;
}
.inlinecardfacility {
  width: 98%;
  display: flex;

  justify-content: center;
  flex-wrap: wrap;
  align-items: start;

  flex-direction: row;
}
.Officebutton:hover {
  cursor: pointer;
  background-color: orange;
}
.inlinecardfacility p {
  color: rgb(65, 64, 64);
}
.pervious {
  background-color: rgb(218, 223, 221);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.WellcomeMessage {
  display: flex;
  min-height: 300px;
  max-width: 700px;
  margin-top: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: -2px 0px 37px 0px rgba(0,0,0,0.24);
  -webkit-box-shadow: -2px 0px 37px 0px rgba(0,0,0,0.24);
  -moz-box-shadow: -2px 0px 37px 0px rgba(0,0,0,0.24);
  justify-content: center;

  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .Logo-container img{
    width: 100px;
    height: 100px;
  }
  .Logo-container {
height: 100px;
width: 90%;

  }
  .Logo-container .headerText .headerTexteach{
    font-size: 15px;
margin: o;
  }
  .Header {
    flex-direction: row;
    height: 100px;

  }
  .Newscard{
    max-width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    flex-direction: column;
  }
  .Newscard img{
    width: 100px;
    height: 100px;


  }
 
  

}
.mobilebody {
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
}
.ECNEwscards {
  width: 800px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.WellcomeMessage img {
  width: 300px;
  height: 84%;
}
.Presidantpic {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Presidantpic img {
 
  width: 228px;
  height: 210px;
}
.Presidantpic p {
  margin: 0px;
  font-weight: bold;
  color: rgb(87, 80, 80);
}
.WellcomeText button {
  height: 35px;
  border: 1px solid orange;
  border-radius: 25px;

  background-color: transparent;
  width: 250px;
}
#Message {
  height: 120px;
  width: 420px;
  

  font-family: 'Alike Angular', serif;
}
.WellcomeMessage p {

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  text-indent: 30px;
}

.WellcomeMessagername {
  margin: 0px;
}
.WellcomeText {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 10px;
  align-items: center;
}
.menusidebar p {
  align-items: center;
}
.Sidemenubutton {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(85, 84, 84, 0.281);
  margin-top: 2px;
}
.menusidebar {
  width: 100vw;
  background: rgb(0,103,184);
  background: linear-gradient(173deg, rgba(0,103,184,1) 0%, rgba(0,103,184,1) 80%, rgba(255,255,255,1) 100%);
  color: white;
  height: 100vh;
  top: 16.5vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  transition: ease 1000ms;
  animation: sidemenu 0.1s;
  right: 0;
  position: absolute;
}
@keyframes sidemenu {
  0% {
    height: 0px;
  }
  100% {
    height: 50vh;
  }
}
.sidemenufinalsub {
  background: rgb(0,103,184);
  background: linear-gradient(173deg, rgba(0,103,184,1) 0%, rgba(0,103,184,1) 80%);
}
.sidemenusub {
  width: 100%;
  background: rgb(1, 44, 77);
  background: linear-gradient(173deg, rgb(2, 50, 87) 0%, rgb(2, 56, 97) 80%);
}
.ECrightBody {
  flex: 1;
  background-color: white;
}
.Smallbanner {
  height: 200px;
  background-color: orange;
  backdrop-filter: blur(2px);
}
.Subheadercomponent ul li {
  list-style: none;
  color: rgb(242, 242, 242);
  margin-left: 10px;
  padding: 5px;
  width: max-content;

  display: flex;
  align-items: center;

  justify-content: center;
}
.Subheadercomponent ul li:hover {
  border-bottom: 1px solid rgb(0, 180, 252);
  cursor: pointer;
}
.Bannercomponent {
  height: 85vh;
  width: 100vw;
  overflow: hidden;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bodycontainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  margin-top: 30px;
  margin-inline: 10px;
}
.leftbodycontainer {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 50px;
  align-items:  start;
  padding-inline: 20px;
}
.Newscard {
 
  margin-top: 20px;
  display: flex;
  margin-inline: 10px;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  box-shadow: -2px 0px 13px 0px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: -2px 0px 13px 0px rgba(0,0,0,0.24);
  -moz-box-shadow: -2px 0px 13px 0px rgba(0, 0, 0, 0.096);
border-radius: 2px;
padding-inline: 20px;

 overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.055);
 max-width: 350px;




}
.Newscard img {
  height: 200px;
  width: 200px;
}
.Newslist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.NewsText {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;

  width: 100%;
}
.NewsText #newsdescription {
  height: 61px;
  margin-bottom: 20px;
  max-width: 600px;
  color: #252525;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-indent: 30px;
}
.hovernews{
  width: 500px;
  border-radius: 2px;
  cursor: pointer;
  
 height: 200px;
  position: absolute;
  background: rgba(0, 0, 0, 0.123);

box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(1px);
-webkit-backdrop-filter: blur(1px);
border: 1px solid rgba(9, 191, 248, 0.3);
  
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-weight: bold;
  display: none;


  
}
.Newscard:hover {
  cursor: pointer;
  background-color: #e9e9e9;
}
#date {
  margin: 0px;
  height: 0px;
  display: flex;
  flex-direction: row;

  font-size: 10px;
  align-items: center;
  justify-content: center;
  color: rgb(103, 69, 196);
  align-self: flex-end;
}
.Newscard #NewsTitle {
  font-weight: bold;
  margin: 0px;
  text-align: center;
  margin-left: 10px;
  color: rgb(18, 65, 92);
}
.Newscard #NewsTitle:hover {
  color: rgb(226, 136, 0);
  cursor: pointer;
}
.cardstitle {
  height: 40px;
  background-color: rgba(231, 229, 229, 0.075);
  display: flex;
  align-items: center;
  justify-content: center;
}
.texteventname {
  flex: 1;
}
.texteventname p {
  margin: 0px;
  padding: 0px;
}
.Text {
  text-align: center;
  color: #737073;
  max-height: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.texteventname #location {
  font-size: 10px;
  color: rgb(66, 34, 34);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.cardelements {
  height: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: rgb(0, 0, 0);
  margin-top: 10px;
  padding: 2px;

  border: 0.5px solid rgba(73, 72, 72, 0.055);
}
.cardelements:hover {
  background-color: #e0dfdf50;
  cursor: pointer;
}
.cardelements img {
  height: 100%;
}
#Newcontainertitle {
  font-weight: bold;
}
.rightbodycontainer {
  flex: 1;
  display: flex;
  align-items: flex-end;
  margin-right: 50px;
  flex-direction: column;
}
.rightbodycontainer .Cards {
  width: 300px;
  background-color: rgba(238, 236, 234, 0.041);
  margin: 5px;
}
.Drop-downAcadmay {
  position: absolute;

  padding-inline: 60px;
  padding-top: 10px;
 width: 500px;
  padding-bottom: 10px;


  background: rgb(1, 79, 139);
  background: linear-gradient(173deg, rgb(1, 48, 83) 0%, rgba(0,103,184,1) 80%);

  
  animation: myAnimation 0.2s;
  display: flex;
  flex-direction: row;
  top: 40px;
  padding-top: 5px;
  flex-wrap: wrap;
}
.dropdownpart {
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: center;
  justify-content: center;
}
.Drop-downlatest {
  position: absolute;
  height: 200px;
  width: 200px;
  background: rgb(1, 79, 139);
  background: linear-gradient(173deg, rgb(1, 48, 83) 0%, rgba(0,103,184,1) 80%);

 
  animation: myAnimation 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 42px;
  padding-top: 5px;
}
.RegstralofficeContact {
  display: flex;
  margin-top: 16vh;
  height: 80vh;
}
.Pagesunderconstraction {
  display: flex;
  margin-top: 16vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  background-color: #252525;
  height: 80vh;
}
#deptitle {
  font-weight: bold;
  animation: slideIn 0.7s ease-in-out;
}
@keyframes myAnimation {
  0% {
    height: 20px;
  }
  100% {
    height: 200px;
  }
}

.eachelement {
  width: 80%;
  height: 30px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
  font-size: 13px;

  border-bottom: 1px solid rgba(10, 132, 153, 0.664);

  animation: slideIn 0.7s ease-in-out;
}
.Footer .text {
  display: flex;
  flex-direction: row;

  height: 90%;
}
.text p {
  margin: 0px;
}
.text-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.text-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fdfdfd;
}
.copyrightmessage {
  flex: 1;
  color: #fdfdfd;
  display: flex;
  align-items: center;
  justify-content: center;
}
#linktitle {
  font-weight: bold;
  margin-top: 10px;
}

#links {
  font-size: 13px;
  color: rgb(0, 217, 255);
  margin-top: 10px;
}
.eachelement p {
  text-align: center;
}
.AcadamicReward {
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

}
.CommunityOutreach {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 700px;
  width: 100%;
  font-weight: bold;
}
.Communitycards {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.178);

  display: flex;
  overflow: hidden;
  display: flex;
}
.Communitycardsout {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.CommunityOutreachheads {
  width: 100%;

  display: flex;
  align-items: center;

  height: 40px;
}
.CommunityCard {
  flex: 1;

  box-shadow: rgba(12, 11, 11, 0.116) 1.2px 1.95px 1.95px 2.6px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.leftcommunitycard {
  flex: 1;
  width: 600px;
  background-color: #312e31;
}
.leftcommunitycard img {
  width: 100%;
  height: 100%;
}
.Rightcommunitycard {
  flex: 1;
  background-color: #0f010175;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.textwidth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.textwidth p {
  font-weight: normal;
}
.box {
  height: 250px;
  width: 80%;
  color: black !important;
  background-color: #ffffff;
  border-radius: 30px;
  margin: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
 
}
.titlesdec{
 
  width: 100%;
  height: 60px;
  margin-inline: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.titlesdec p{
  color: black;
  font-size: 30px;
  font-weight: bold;
}
.box .boxdetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 1;
  width: 200px;
  height: 120px;
}
.boxdetail p {
  color: rgb(11, 4, 68);
  margin: 0px;
}
#No {
  font-size: 60px;
  font-weight: bold;
}
#value {
  font-weight: bold;
  color: #070042;
}
.eachelement:hover {
  background-color: rgba(148, 148, 144, 0.116);
  cursor: pointer;
}
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Newscontainerlist {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background-color: rgba(240, 248, 255, 0.301);
  padding-inline: 5px;
}
.seemore:hover {
  color: rgb(13, 13, 119);
  cursor: pointer;
}
.Newscontainerlist #Newcontainertitle {
  font-weight: bold;
  color: rgb(31, 30, 30);
}

.Banner-darken {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.667);
  display: flex;
  flex-direction: row;
}
.smallBanner-darken {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.552);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.smallBanner-darken p {
  color: white;
  text-align: center;
  font-size: 30px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  margin-top: 100px;
}
.Wellcome-text {
  color: white;
  flex: 1;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}
.Wellcombutton {
  flex: 1;
}
.Wellcome-text p {
  margin: 0px;

  text-align: center;
  padding: 0px;
}
.Banner-darken .Wellcome-text #wellcome {
  font-weight: bolder;
  font-size: 36px;
  font-family: 'Alike Angular', serif;
}
.Wellcome-text button {
  margin-top: 20px;
  background-color: transparent;
  height: 40px;
  color: orange;

  border: 1px solid rgb(253, 253, 253);
  border-radius: 12px;
  width: 90px;
}
.Wellcome-text button:hover {
  background-color: rgba(0, 217, 255, 0.281);
  color: white;
  font-family: 'Alike Angular', serif;
}

.slideshow {
  margin: 0 auto;
  overflow: hidden;
}
.Loadingpage {
  margin-top: 16vh;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
}
.Loadingpage p {
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.371);
  text-align: center;
}

.slideshowSlider {
  white-space: nowrap;

  max-height: 450px;

  transition: ease 1000ms;
}
.Aboutpages {
  margin-top: 16vh;

  background-color: #fff;
}
.AboutContainer {
  margin: auto;
  width: 98vw;

  text-align: center;
}
.GallaryPages {
  margin-top: 16vh;

  background-color: #fff;

  display: flex;
  flex-direction: column;

  justify-content: center;
}
.GallaryPages p {
  font-weight: bold;
}

.Images-container {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  margin-top: 20px;
  justify-content: space-evenly;
}
.gallarycard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.gallarycard p {
  font-weight: bold;
}
.Aboutpages p {
  width: 800px;
  margin: auto;
  padding-bottom: 20px;
  color: rgba(41, 36, 41, 0.8);

  font-size: 18px;
  margin-bottom: 45px;
}
.Aboutpages h1 {
  color: rgba(41, 36, 41, 0.8);
  font-size: 32px;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #161318;
}
.Calander {
  width: 800px;
  height: 85vh;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
}
.Calanderpage {
  margin-top: 16vh;

  display: flex;
  align-items: center;
  justify-content: center;
}
.CalanderdateCoponents {
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.227);

  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  width: 100px;
}
.day {
  flex: 1;
}
.dayevent {
  flex: 1;
  overflow: hidden;
  font-size: 12px;
}
.NEWspageTitle {
  font-size: 35px;
  font-weight: bold;
}
.newsdate {
  color: #716f71;
  width: 78%;
  font-weight: bold;
}
.SideNEws {
  width: 500px;
}
.MainNewsdetail {
  margin-top: 40px;
  width: 98%;

  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;
}
.MainNewsdetail p {
  width: 95%;

  text-align: center;
}
.center {
  width: 99%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Newsdetailpages {
  margin-top: 16vh;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  flex-direction: row;
}
.listCard {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-around;
  box-shadow: rgba(12, 11, 11, 0.041) 1.2px 1.95px 1.95px 2.6px;
}

.Questioncard {
  margin-inline: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.Questioncard:hover {
  cursor: pointer;
  color: #0067b8;
 
}
.hiddentext {
  display: none;

  animation: myAnimation 0.2s;
}
.listCard p {
  font-weight: bold;
}
.Admissionpage {
  margin-top: 16vh;
  display: flex;
  align-items: center;
  
  justify-content: center;
  align-items: center;
}
.Admissioncontainer {
  padding: 100px;
}
.Admissionpagetext {
  width: 90vw;
}
.MainNewsdetail img {
  width: 600px;
  height: 400px;
}
.CalanderdateCoponents p {
  margin: 0px;
}
.year p {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.year {
  display: flex;

  align-items: center;
  justify-content: space-between;
}
.Downloadpage {
  margin-top: 16vh;
  height: 85vh;
}

.DepartmentPage {
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  margin-top: 16vh;
}
.headline {
  display: inline-block;
  width: 100%;
  height: 500px;
  background-color: black;
}
.headline .h-0 {
  padding-top: 100px;
  color: white;
  font-size: 4em;
}
.headline .h-2 {
  margin-top: -1em;
  color: white;
}
.mid-text {
  position: absolute;
  display: inline-block;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.h-1 {
  width: 100%;
  background-color: orange;
}
.h1 {
  font-size: xx-large;
  color: white;
}
.content {
  border-radius: 14px;
  outline: 2px solid gray;
  padding: 10px 10px 10px 10px;
  margin-top: -1em;
}

/*downloadpage css*/
.my-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  text-decoration: none;
  color: #333;
}
.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}

.icon {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.icon img {
  max-width: 100%;
  max-height: 100%;
}

.description {
  flex: 1 1 auto;
}

.description h3 {
  margin: 0 0 5px 0;
  font-size: 18px;
}

.description p {
  margin: 0;
  font-size: 14px;
}
